<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
$primary-color: #091E42;

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: $primary-color;
  font-family: canada-type-gibson, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, button {
  font-family: canada-type-gibson, sans-serif;
}

.container {
  padding: 32px;
  width: 100%;
  max-width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 760px) {
    padding: 16px;
    max-width: 90%;
  }
}

.df {
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-background-image, iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  z-index: 1;
}

.start-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: $primary-color;
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: $primary-color;
  }
}

.video-div {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100vh;

  iframe {
    bottom: 20%;
    border: none;
    width: 100%;
    height: 83%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background-color: $primary-color;
  }

  .video-placeholder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
  }
}
</style>
