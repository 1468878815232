import {createRouter, createWebHashHistory} from 'vue-router';
import inputPageVideos from '../views/input-page-video';
import inputPagePhotos from "@/views/input-page-photo";
import videosPage from "@/views/videos-page";
import photosPage from "@/views/photos-page";

const routes = [
    {
        path: '/:eventID',
        component: inputPagePhotos,
        meta: {
            title: 'Cheerzone - TRACX'
        }
    },
    {
      path: '/1138',
      redirect: '/1138/videos',
    },
    {
      path: '/:eventID/videos',
      component: inputPageVideos,
      meta: {
        title: 'Cheerzone - TRACX'
      }
    },
    {
        path: '/:eventID/videos/:startNumber',
        component: videosPage,
        meta: {
            title: 'Cheerzone video\'s - TRACX'
        }
    },
    {
        path: '/:eventID/photos/:startNumber',
        component: photosPage,
        meta: {
            title: 'Cheerzone photos - TRACX'
        }
    },
    {
        path: '/',
        redirect: '/1143'
    }
];

const router = new createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router;
